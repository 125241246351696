import { Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useGetCoreJourneysData from '../../../../hooks/useGetCoreJourneysData/useGetCoreJourneysData'
import ButtonMenu, {
    ButtonMenuOption
} from '../../../elements/ButtonMenu/ButtonMenu'
import { MultiSelectOption } from '../../../elements/MultiSelect/MultiSelect'
import ChallengeFilterSkeleton from './ChallengeFilterSkeleton'
import { ChallengeFilterTopic } from '../../../../hooks/useGetChallengesHomeData/useGetChallengesHomeData'

const messages = defineMessages({
    company: { defaultMessage: 'Company', description: 'company option' },
    group: { defaultMessage: 'Group', description: 'group option' },
    personal: { defaultMessage: 'Personal', description: 'personal option' },
    allTopics: {
        defaultMessage: 'All topics',
        description: 'all topics option'
    },
    biotype: { defaultMessage: 'Biotype', description: 'biotype option' },
    challengeTopicAriaLabel: {
        defaultMessage: 'challenge topic',
        description: 'challenge topic'
    }
})

export interface ChallengeFilterSelection {
    options: MultiSelectOption[]
    topic: string | undefined
}
interface ChallengeFilterProps {
    onChange: (selection: ChallengeFilterSelection) => void
    loading?: boolean
}

const ChallengeFilter: React.FC<ChallengeFilterProps> = ({
    onChange,
    loading
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage } = useIntl()
    const { journeys } = useGetCoreJourneysData()

    const [options, setOptions] = useState<MultiSelectOption[]>([])

    useEffect(() => {
        setOptions((prevOptions) =>
            prevOptions.map((option) => ({ ...option, active: true }))
        )
    }, [formatMessage])

    const handleSelection = useCallback(
        (selection: ChallengeFilterSelection) => {
            setOptions(selection.options)
            onChange(selection)
        },
        [onChange]
    )

    if (loading) return <ChallengeFilterSkeleton />

    return (
        <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            gap={1.5}
            role="combobox"
            aria-label={formatMessage(messages.challengeTopicAriaLabel)}
        >
            <CoreTypography variant="body1" tabindex="0">
                <FormattedMessage
                    defaultMessage="Challenge topic"
                    description="challenge topic filter"
                />
            </CoreTypography>
            <ButtonMenu
                color="primary"
                options={
                    journeys
                        .map((journey) => ({
                            label: journey.name,
                            values: [journey.name]
                        }))
                        .concat({
                            label: formatMessage(messages.biotype),
                            values: [ChallengeFilterTopic.BIOTYPE]
                        }) as ButtonMenuOption[]
                }
                defaultTitle={formatMessage(messages.allTopics)}
                onSelect={(option) =>
                    handleSelection({ options, topic: option.values[0] })
                }
            />
        </Stack>
    )
}

export default memo(ChallengeFilter)
